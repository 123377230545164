export const AgentRegexPattern = {
	AndroidWeb: /Android/,
	iOSWeb: /iPhone/,
	MobileWeb: /Mobile|mobile/,
};

export enum Agent {
	ANDROID_WEB = 'android_web',
	IOS_WEB = 'ios_web',
	PC_WEB = 'pc_web',
	ETC = 'etc',
}
