import React from 'react';
import styled from '@emotion/styled';
import { MOBILE_HEADER_HEIGHT } from '../constants/layout';
import { pxToRem } from '../utils/common';

const AnchorLink = ({ id }: { id: string }) => {
	return <Anchor id={id} />;
};

export default AnchorLink;

const Anchor = styled.a`
	display: block;
	margin-top: ${pxToRem((MOBILE_HEADER_HEIGHT - 30) * -1)};
	padding-top: ${pxToRem(MOBILE_HEADER_HEIGHT - 30)};
`;
