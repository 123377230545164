import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useRef, useState } from 'react';
import { BLACK } from '../../constants/colors';
import { INNER_ELEMENT_WIDTH, WINDOW_MIN_WIDTH } from '../../constants/layout';
import { ANCHOR_IDS } from '../../constants/urls';
import { HeaderMenuType, HeaderStyleType } from '../../data/header';
import { historyDataDesktop, historyDataMobile } from '../../data/history';
import useChangeHeaderStyle from '../../hooks/useChangeHeaderStyle';
import { UrbaneFont } from '../../styles/common';
import { getDesktopStyle, showOnDesktopStyle, showOnMobileStyle } from '../../styles/layout';
import { pxToRem } from '../../utils/common';
import AnchorLink from '../AnchorLink';
import SectionTitleSet from '../common/SectionTitleSet';
import HistoryListItem from '../historySection/HistoryListItem';

// 연혁 섹션
const HistorySection = () => {
	const sectionRef = useRef<HTMLElement>(null);
	const [openedRowId, setOpenedRowId] = useState<number | undefined>();
	useChangeHeaderStyle({
		sectionRef,
		currentType: HeaderMenuType.Story,
		currentStyleType: HeaderStyleType.White,
		nextStyleType: HeaderStyleType.White,
	});

	const onClickItem = useCallback(
		(id: number) => {
			if (openedRowId === id) {
				setOpenedRowId(undefined);
			} else {
				setOpenedRowId(id);
			}
		},
		[openedRowId],
	);

	return (
		<>
			<AnchorLink id={ANCHOR_IDS.HISTORY} />
			<Wrapper ref={sectionRef}>
				<SectionTitleSet
					title={
						<>
							O2O Platform for
							<br />
							Petmily Life
						</>
					}
					description={
						<>
							<span css={showOnDesktopStyle}>
								반려동물의 생애주기에 맞춘 전문적이고 간편한 케어를 위해
								<br />
								펫닥은 보호자들에게 최적화된 서비스들을 제공합니다.
							</span>
							<span css={showOnMobileStyle}>
								펫닥은 반려동물 생애주기에 맞춘
								<br />
								전문 서비스를 제공합니다.
							</span>
						</>
					}
					textAlign={'CENTER'}
				/>
				<HistoryListDesktop>
					{historyDataDesktop.map((data) => (
						<HistoryListItem key={data.id} data={data} isOpen={openedRowId === data.id} onClick={onClickItem} />
					))}
				</HistoryListDesktop>
				<HistoryListMobile>
					{historyDataMobile.map((data) => (
						<HistoryListItem key={data.id} data={data} isOpen={openedRowId === data.id} onClick={onClickItem} />
					))}
				</HistoryListMobile>
				<SloganText>
					From Cradle
					<br />
					to Grave
				</SloganText>
			</Wrapper>
		</>
	);
};

export default HistorySection;

const historyListStyle = css`
	border-top: 0.75px solid ${BLACK};
	margin-top: ${pxToRem(48)};
	margin-bottom: ${pxToRem(20)};

	${getDesktopStyle(css`
		margin: 0 auto;
		border-top: 1px solid ${BLACK};
		max-width: ${pxToRem(INNER_ELEMENT_WIDTH)};
		margin-top: ${pxToRem(44)};
		margin-bottom: ${pxToRem(50)};
	`)}
`;

const SloganText = styled.p`
	${UrbaneFont};
	font-weight: 700;
	font-size: ${pxToRem(25)};
	line-height: ${pxToRem(30)};
	text-align: right;
	margin: 0 auto;

	${getDesktopStyle(css`
		font-size: ${pxToRem(70)};
		line-height: ${pxToRem(84)};
		max-width: ${pxToRem(INNER_ELEMENT_WIDTH)};
	`)}
`;

const HistoryListMobile = styled.ul`
	${showOnMobileStyle};
	${historyListStyle};
`;

const HistoryListDesktop = styled.ul`
	${showOnDesktopStyle};
	${historyListStyle};
`;

const Wrapper = styled.section`
	color: ${BLACK};
	text-align: center;
	padding: ${pxToRem(80)} ${pxToRem(24)} ${pxToRem(20)};

	${getDesktopStyle(css`
		min-width: ${pxToRem(WINDOW_MIN_WIDTH)};
		padding: ${pxToRem(116)} 0 ${pxToRem(82)};
	`)}
`;
