import { css } from '@emotion/react';
import { pxToRem } from '../utils/common';

// Swipter slide pagenation mobile 스타일링(점 모양)
export const swiperPagenationStyle = ({
	bgColor,
	activeColor,
	bottom,
}: {
	bgColor: string;
	activeColor: string;
	bottom?: number;
}) => css`
	.swiper-horizontal > .swiper-pagination-bullets,
	.swiper-pagination-bullets.swiper-pagination-horizontal,
	.swiper-pagination-custom,
	.swiper-pagination-fraction {
		position: absolute;
		bottom: ${bottom ? pxToRem(bottom) : 0};
	}

	.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
	.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
		width: ${pxToRem(4)};
		height: ${pxToRem(4)};
		background-color: ${bgColor};
		opacity: 1;
		margin: 0 ${pxToRem(6)};

		&.swiper-pagination-bullet-active {
			background-color: ${activeColor};
		}
	}
`;
