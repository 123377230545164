import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import { pxToRem } from '../../utils/common';
import SideBarSubMenuItem from './SideBarSubMenuItem';
import { SubMenuType } from './SideBarNavItem';
import { useSetSideBarState } from '../providers/SideBarProvider';

interface SideBarSubMenuProps {
	isSubMenuOpen: boolean;
	subMenu?: SubMenuType[];
}

const SideBarSubMenu = ({ isSubMenuOpen, subMenu }: SideBarSubMenuProps) => {
	const setIsSideBarOpen = useSetSideBarState();

	// SideBar open 여부 제어
	const handleSideBarClose = useCallback(() => {
		setIsSideBarOpen(false);
	}, []);

	return (
		<SideBarSubMenuWrapper isSubMenuOpen={isSubMenuOpen}>
			{subMenu &&
				subMenu.map((menu) => {
					return <SideBarSubMenuItem key={menu.id} data={menu} handleSideBarClose={handleSideBarClose} />;
				})}
		</SideBarSubMenuWrapper>
	);
};

export default SideBarSubMenu;

const SideBarSubMenuWrapper = styled.ul(
	({ isSubMenuOpen }: { isSubMenuOpen: boolean }) => css`
		display: ${isSubMenuOpen ? 'block' : 'none'};
		padding: ${pxToRem(4)} 0 ${pxToRem(12)};
		transform-origin: top center;
	`,
);
