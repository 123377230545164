import { IMAGE_BASE_URL } from '../constants/urls';

const VET_IMG_01 = `${IMAGE_BASE_URL}/vet/vet_image_01.png`;
const VET_IMG_02 = `${IMAGE_BASE_URL}/vet/vet_image_02.png`;
const VET_CARD_IMG_02 = `${IMAGE_BASE_URL}/vet/vet_image_02_02.png`;
const VET_IMG_03 = `${IMAGE_BASE_URL}/vet/vet_image_03.png`;

export enum VetType {
	Lee = 'lee',
}

export interface VetItemType {
	id: number;
	name: string;
	position: string;
	additionalDescription: string;
	careerDesktop: string[];
	career: string[];
	treatmentSubjects: string[];
	image: any;
}

export const vetImageData = [{ id: 0, type: VetType.Lee, image: VET_IMG_02, name: '이태형' }];

export const vetData = [
	{
		id: 0,
		name: '이태형',
		position: '부의장',
		additionalDescription: '수의 한방 침치료 인증 수의사 | CVA',
		careerDesktop: [
			'경북대학교 수의과대학 수의학과 졸업',
			'(현)대한수의사회 반려동물 식품 특별위원',
			'(현)서울시 수의사회 상임이사',
			'(현)(사)동물복지표준협회 대표',
			'(현)서초 이태형 반려동물 건강검진센터 대표 원장',
			'(현)(주)펫닥 부의장',
		],
		career: [
			'경북대학교 수의과대학 수의학과 졸업',
			'(현)대한수의사회 반려동물 식품\n특별위원',
			'(현)서울시 수의사회 상임이사',
			'(현)(사)동물복지표준협회 대표',
			'(현)서초 이태형 반려동물\n건강검진센터 대표 원장',
			'(현)(주)펫닥 부의장',
		],
		treatmentSubjects: [
			'일반내과',
			'강아지, 고양이 치과',
			'영양학 관리',
			'호르몬질환',
			'노령동물',
			'일반외과',
			'한방, 재활 물리치료',
			'건강검진 & 예방의학',
		],
		image: VET_CARD_IMG_02,
	},
];
