import React from 'react';
import { GRAY_03, WHITE } from '../../../constants/colors';

const KinderIcon = ({ isActive = false }: { isActive?: boolean }) => {
	return (
		<svg width="51" height="46" viewBox="0 0 51 46" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M44.6475 21.1172L44.0036 21.8823L44.6475 21.1172ZM25.7696 5.23062L25.1257 5.99574L25.7696 5.23062ZM6.5054 21.1172L5.86151 20.3521L6.5054 21.1172ZM2.10743 20.6581L1.46355 19.893L2.10743 20.6581ZM49.0454 20.6581L49.6893 19.893L49.0454 20.6581ZM25.7696 1.07046L25.1257 1.83559L25.7696 1.07046ZM25.3833 1.07046L24.7394 0.30534L25.3833 1.07046ZM25.1257 1.83559L48.4015 21.4232L49.6893 19.893L26.4135 0.305341L25.1257 1.83559ZM2.75132 21.4232L26.0271 1.83559L24.7394 0.30534L1.46355 19.893L2.75132 21.4232ZM6.31223 20.1876H2.3006V22.1876H6.31223V20.1876ZM7.14928 21.8823L26.0271 5.99574L24.7394 4.4655L5.86151 20.3521L7.14928 21.8823ZM25.1257 5.99574L44.0036 21.8823L45.2913 20.3521L26.4135 4.4655L25.1257 5.99574ZM48.8523 20.1876H44.8406V22.1876H48.8523V20.1876ZM44.0036 21.8823C44.2379 22.0795 44.5343 22.1876 44.8406 22.1876V20.1876C45.0055 20.1876 45.1652 20.2459 45.2913 20.3521L44.0036 21.8823ZM26.0271 5.99574C25.7666 6.21496 25.3862 6.21496 25.1257 5.99574L26.4135 4.4655C25.9297 4.05838 25.2232 4.05837 24.7394 4.4655L26.0271 5.99574ZM6.31223 22.1876C6.61851 22.1876 6.91495 22.0795 7.14928 21.8823L5.86151 20.3521C5.9877 20.2459 6.14732 20.1876 6.31223 20.1876V22.1876ZM1.46355 19.893C0.535638 20.6739 1.08782 22.1876 2.3006 22.1876V20.1876C2.95363 20.1876 3.25096 21.0028 2.75132 21.4232L1.46355 19.893ZM48.4015 21.4232C47.9019 21.0028 48.1992 20.1876 48.8523 20.1876V22.1876C50.065 22.1876 50.6172 20.6739 49.6893 19.893L48.4015 21.4232ZM26.4135 0.305341C25.9297 -0.101775 25.2232 -0.101785 24.7394 0.30534L26.0271 1.83559C25.7666 2.05481 25.3862 2.0548 25.1257 1.83559L26.4135 0.305341Z"
				fill={isActive ? WHITE : GRAY_03}
			/>
			<mask id="path-2-inside-1_134_1858" fill="white">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M17.014 35.1333C17.2645 31.155 20.8484 28 25.2327 28C29.6171 28 33.201 31.155 33.4515 35.1333H33.4656V45.8329H17.0001V35.6084C17 35.5985 17 35.5887 17 35.5789C17 35.569 17 35.5592 17.0001 35.5494V35.1333H17.014Z"
				/>
			</mask>
			<path
				d="M17.014 35.1333V37.1333H18.892L19.01 35.259L17.014 35.1333ZM33.4515 35.1333L31.4555 35.259L31.5735 37.1333H33.4515V35.1333ZM33.4656 35.1333H35.4656V33.1333H33.4656V35.1333ZM33.4656 45.8329V47.8329H35.4656V45.8329H33.4656ZM17.0001 45.8329H15.0001V47.8329H17.0001V45.8329ZM17.0001 35.6084H19.0001L19 35.6001L17.0001 35.6084ZM17.0001 35.5494L19.0001 35.5576V35.5494H17.0001ZM17.0001 35.1333V33.1333H15.0001V35.1333H17.0001ZM19.01 35.259C19.1859 32.4668 21.7662 30 25.2327 30V26C19.9306 26 15.3432 29.8432 15.0179 35.0076L19.01 35.259ZM25.2327 30C28.6993 30 31.2796 32.4668 31.4555 35.259L35.4475 35.0076C35.1223 29.8432 30.5349 26 25.2327 26V30ZM33.4515 37.1333H33.4656V33.1333H33.4515V37.1333ZM31.4656 35.1333V45.8329H35.4656V35.1333H31.4656ZM33.4656 43.8329H17.0001V47.8329H33.4656V43.8329ZM19.0001 45.8329V35.6084H15.0001V45.8329H19.0001ZM19 35.6001C19 35.593 19 35.5859 19 35.5789H15C15 35.5915 15 35.604 15.0001 35.6166L19 35.6001ZM19 35.5789C19 35.5718 19 35.5647 19 35.5576L15.0001 35.5411C15 35.5537 15 35.5663 15 35.5789H19ZM19.0001 35.5494V35.1333H15.0001V35.5494H19.0001ZM17.0001 37.1333H17.014V33.1333H17.0001V37.1333Z"
				fill={isActive ? WHITE : GRAY_03}
				mask="url(#path-2-inside-1_134_1858)"
			/>
			<path d="M9 19V45H42V19" stroke={isActive ? WHITE : GRAY_03} strokeWidth="2" />
		</svg>
	);
};

export default KinderIcon;
