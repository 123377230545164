import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { WHITE } from '../../constants/colors';
import { fourPawsData } from '../../data/fourpaws';
import { getDesktopStyle, showOnDesktopStyle } from '../../styles/layout';
import { pxToRem } from '../../utils/common';

const FourPawsListItemDesktop = () => {
	const [isHovering, setIsHovering] = useState(0);

	return (
		<FourPawsListWrapper>
			{fourPawsData.map((data) => (
				<FourPawsItemList key={data.id} isHovered={data.id === isHovering} onClick={() => setIsHovering(data.id)}>
					<FourPawsItemCircle>
						<FourPawsItemTitle isHovered={data.id === isHovering}>
							{data.title.split('\n').map((title, index) => {
								return <p key={index}>{title}</p>;
							})}
						</FourPawsItemTitle>
						<FourPawsItemDescription isHovered={data.id === isHovering}>
							{data.description.split('\n').map((description, index) => {
								return <p key={index}>{description}</p>;
							})}
						</FourPawsItemDescription>
					</FourPawsItemCircle>
				</FourPawsItemList>
			))}
		</FourPawsListWrapper>
	);
};

export default FourPawsListItemDesktop;

const FourPawsListWrapper = styled.ul`
	${showOnDesktopStyle};
	${getDesktopStyle(css`
		display: flex;
		flex-flow: row wrap;
		margin-top: ${pxToRem(302)};
		justify-content: center;
		align-items: center;
	`)}
`;

const FourPawsItemList = styled.li(
	({ isHovered }: { isHovered: boolean }) => css`
		width: ${isHovered ? pxToRem(288) : pxToRem(200)};
		height: ${isHovered ? pxToRem(288) : pxToRem(200)};
		line-height: ${isHovered ? pxToRem(288) : pxToRem(200)};
		margin-left: ${pxToRem(54)};
		border-radius: 50%;
		text-align: center;
		background: ${isHovered ? 'rgba(0, 0, 0, 0.7)' : 'rgba(0, 0, 0, 0.55)'};
		color: ${WHITE};
		transition: all 0.5s ease-in-out;
		cursor: pointer;
		&:first-of-type {
			margin-left: 0;
		}
	`,
);

const FourPawsItemCircle = styled.dl`
	display: inline-block;
	line-height: normal;
	vertical-align: middle;
`;

const FourPawsItemTitle = styled.dt(
	({ isHovered }: { isHovered: boolean }) => css`
		margin-bottom: ${isHovered ? pxToRem(20) : 0};
		font-size: ${isHovered ? pxToRem(25) : pxToRem(20)};
		transition: all 0.5s ease-in-out;
	`,
);

const FourPawsItemDescription = styled.dd(
	({ isHovered }: { isHovered: boolean }) => css`
		display: ${isHovered ? 'block' : 'none'};
		transition: all 0.5s ease-in-out;
	`,
);
