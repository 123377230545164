import { SerializedStyles } from '@emotion/react';
import { Link } from 'gatsby';
import React, { ReactNode } from 'react';
import { ButtonColorType, MobileLineButtonStyle } from '../../styles/button';

const MobileLineLinkButton = ({
	type = 'WHITE',
	children,
	link,
	style,
	isInternalLink = false,
}: {
	type?: ButtonColorType;
	children: ReactNode;
	link: string;
	isInternalLink?: boolean;
	style?: SerializedStyles;
}) => {
	return (
		<>
			{isInternalLink ? (
				<Link css={[MobileLineButtonStyle({ type }), style]} to={link}>
					{children}
				</Link>
			) : (
				<a
					css={[MobileLineButtonStyle({ type }), style]}
					href={link}
					target={isInternalLink ? '_self' : '_blank'}
					rel="noopener noreferrer"
				>
					{children}
				</a>
			)}
		</>
	);
};

export default MobileLineLinkButton;
