import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useRef } from 'react';
import { BLACK, RED, WHITE } from '../../constants/colors';
import { WINDOW_MIN_WIDTH } from '../../constants/layout';
import { ANCHOR_IDS } from '../../constants/urls';
import { ZIndex } from '../../constants/zIndex';
import { HeaderMenuType, HeaderStyleType } from '../../data/header';
import { vetData } from '../../data/vet';
import useChangeHeaderStyle from '../../hooks/useChangeHeaderStyle';
import { UrbaneFont } from '../../styles/common';
import { getDesktopStyle, showOnDesktopStyle, showOnMobileStyle } from '../../styles/layout';
import { pxToRem } from '../../utils/common';
import AnchorLink from '../AnchorLink';
import SectionTitle from '../common/SectionTitle';
import VetCardItem from '../vetSection/VetCardItem';

const VetSection = () => {
	const sectionRef = useRef<HTMLElement>(null);

	useChangeHeaderStyle({
		sectionRef,
		currentType: HeaderMenuType.Story,
		currentStyleType: HeaderStyleType.White,
		nextStyleType: HeaderStyleType.Black,
	});

	return (
		<>
			<AnchorLink id={ANCHOR_IDS.VETERIANARIAN} />
			<Wrapper ref={sectionRef}>
				<ContentWrapper>
					<TitleWrapperDesktop>
						<SectionTitle textAlign="CENTER">
							<TitlePoint>Vet</TitlePoint>erinarian
						</SectionTitle>
						<SubTitle>펫닥의 수의사</SubTitle>
					</TitleWrapperDesktop>
					<TitleWrapperMobile>
						<SectionTitleMobile>
							<TitlePoint>Vet</TitlePoint>erinarian
						</SectionTitleMobile>
						<SubTitle>펫닥의 수의사</SubTitle>
					</TitleWrapperMobile>
					<ContentDesktop>
						<Description>
							<span css={showOnDesktopStyle}>
								펫닥은 반려동물과 보호자들에게 꼭 필요한 서비스와 제품을
								<br />
								제공하기 위해 여러 전문 분야의 인력을 통해 검증을 진행합니다.
							</span>
							<span css={showOnMobileStyle}>
								꼭 필요한 서비스와 제품을 제공하기 위해
								<br />
								다양한 전문가 검증을 진행합니다.
							</span>
						</Description>
						<VetCardItem itemData={vetData[0]} />
					</ContentDesktop>
				</ContentWrapper>
			</Wrapper>
		</>
	);
};

export default VetSection;

const Description = styled.p`
	display: block;
	width: 100%;
	text-align: center;
	font-size: ${pxToRem(15)};
	line-height: ${pxToRem(24)};
	margin-top: ${pxToRem(24)};
	margin-bottom: ${pxToRem(16)};

	span {
		font-weight: 400;
	}

	${getDesktopStyle(css`
		font-size: ${pxToRem(20)};
		line-height: ${pxToRem(27)};
		margin-top: ${pxToRem(24)};

		span {
			font-weight: 500;
		}
	`)}
`;

const PageNavButtonStyle = css`
	display: flex;
	justify-content: space-between;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: ${ZIndex.PageNavButton};
	width: ${pxToRem(26)};
	height: ${pxToRem(34)};
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;

	&:after {
		content: none;
	}
`;

const ContentDesktop = styled.div`
	position: relative;

	${getDesktopStyle(css`
		position: relative;
		height: 100%;
	`)}
`;

const SubTitle = styled.p`
	font-weight: 700;
	font-size: ${pxToRem(30)};
	text-align: center;

	${getDesktopStyle(css`
		font-size: ${pxToRem(58)};
	`)}
`;

const TitlePoint = styled.span`
	${UrbaneFont};
	color: ${RED};
	font-weight: 700;
`;

const ContentWrapper = styled.div`
	height: 100%;
	margin: 0 auto;
`;

const SectionTitleMobile = styled.h1`
	${UrbaneFont};
	text-align: center;
	font-size: ${pxToRem(34)};
	line-height: ${pxToRem(35)};
	font-weight: 600;
`;

const TitleWrapperMobile = styled.div`
	${showOnMobileStyle};
`;

const TitleWrapperDesktop = styled.div`
	${showOnDesktopStyle};
`;

const Wrapper = styled.section`
	background-color: ${WHITE};
	padding: ${pxToRem(80)} ${pxToRem(24)};
	color: ${BLACK};

	${getDesktopStyle(css`
		min-width: ${pxToRem(WINDOW_MIN_WIDTH)};
		padding: ${pxToRem(116)} 0 ${pxToRem(75)};
	`)}
`;
