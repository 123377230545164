import { css } from '@emotion/react';
import { BLACK, WHITE } from '../constants/colors';
import { pxToRem } from '../utils/common';
import { showOnMobileStyle } from './layout';

export type ButtonColorType = 'WHITE' | 'BLACK';

export const MobileLineButtonStyle = ({ type }: { type: ButtonColorType }) => css`
	${showOnMobileStyle};
	color: ${type === 'BLACK' ? BLACK : WHITE};
	border: 1px solid ${type === 'BLACK' ? BLACK : WHITE};
	min-width: ${pxToRem(84)};
	padding: 0 ${pxToRem(10)};
	font-size: ${pxToRem(12)};
	font-weight: 500;
	border-radius: ${pxToRem(12.5)};
	height: ${pxToRem(25)};
	line-height: ${pxToRem(23)};
	display: inline-block;
`;
