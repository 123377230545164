import React from 'react';
import { GRAY_03, WHITE } from '../../../constants/colors';

const GroomingICon = ({ isActive = false }: { isActive?: boolean }) => {
	return (
		<svg width="39" height="51" viewBox="0 0 39 51" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M24.626 24.877L32.6222 24.877"
				stroke={isActive ? WHITE : GRAY_03}
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M24.626 21.3232L32.6222 21.3232"
				stroke={isActive ? WHITE : GRAY_03}
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M24.626 17.769L32.6222 17.769"
				stroke={isActive ? WHITE : GRAY_03}
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M24.626 14.2153L32.6222 14.2153"
				stroke={isActive ? WHITE : GRAY_03}
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M24.626 10.6616L32.6222 10.6616"
				stroke={isActive ? WHITE : GRAY_03}
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M24.626 7.10791H36.953C37.5053 7.10791 37.953 7.55563 37.953 8.10791V47.8659C37.953 48.4181 37.5053 48.8659 36.953 48.8659H33.6681C33.0982 48.8659 32.6435 48.3903 32.6691 47.821L33.4637 30.1256C33.4893 29.5563 33.0346 29.0807 32.4647 29.0807H24.626"
				stroke={isActive ? WHITE : GRAY_03}
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path d="M9.52197 38.2041H10.4104" stroke={isActive ? WHITE : GRAY_03} strokeWidth="2.5" strokeLinecap="round" />
			<path d="M13.9642 38.2041H14.8527" stroke={isActive ? WHITE : GRAY_03} strokeWidth="2.5" strokeLinecap="round" />
			<path
				d="M8.63345 44.4233L11.3491 6.23474C11.3927 5.62083 12.2914 5.61289 12.3459 6.22594L15.7412 44.4233"
				stroke={isActive ? WHITE : GRAY_03}
				strokeWidth="2"
			/>
			<circle cx="5.04301" cy="45.9366" r="4.04301" stroke={isActive ? WHITE : GRAY_03} strokeWidth="2" />
			<circle cx="19.3317" cy="45.9366" r="4.04301" stroke={isActive ? WHITE : GRAY_03} strokeWidth="2" />
			<circle
				cx="12.138"
				cy="26.7035"
				r="1.4276"
				fill={isActive ? WHITE : GRAY_03}
				stroke={isActive ? WHITE : GRAY_03}
				strokeWidth="2"
			/>
		</svg>
	);
};

export default GroomingICon;
