import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { vAdDataItem } from '../../data/vAd';
import { UrbaneFont } from '../../styles/common';
import { getDesktopStyle } from '../../styles/layout';
import { pxToRem } from '../../utils/common';
import { isMobile } from '../../utils/userAgent';

interface vAdListItemProps {
	data: vAdDataItem;
}

const VadListItem = (adList: vAdListItemProps) => {
	const adCount = adList.data.count;
	const [scrollY, setScrollY] = useState(0);
	const [targetScrollYNumber, setTargetScrollYNumber] = useState(7918);
	const handleFollow = () => {
		setScrollY(window.pageYOffset); // window 스크롤 값을 ScrollY에 저장
	};

	// useEffect(() => {}, [scrollY]);

	useEffect(() => {
		const watch = () => {
			window.addEventListener('scroll', handleFollow);
		};
		watch(); // addEventListener 함수를 실행
		return () => {
			window.removeEventListener('scroll', handleFollow); // addEventListener 함수를 삭제
		};
	});

	useEffect(() => {
		setTargetScrollYNumber(isMobile() ? 4584 : 7918);
		// mo : 4584, pc: 7918
	}, []);

	return (
		<VadItem>
			<VadItemImageWrapper>
				<VadItemImage src={adList.data.image} alt="" />
			</VadItemImageWrapper>
			<dl>
				<VadItemTitle>{adList.data.text}</VadItemTitle>
				<VadItemDescription>
					{scrollY > targetScrollYNumber ? (
						<VadItemCounting
							start={0}
							end={adCount}
							decimals={String(adCount).includes('.') ? 1 : 0}
							duration={1.5}
							separator=","
						></VadItemCounting>
					) : (
						<VadItemCount>{adCount.toLocaleString()}</VadItemCount>
					)}
					{adList.data.unit}
				</VadItemDescription>
			</dl>
		</VadItem>
	);
};

export default VadListItem;

const VadItem = styled.li``;

const VadItemImageWrapper = styled.span`
	display: block;
`;

const VadItemImage = styled.img`
	width: auto;
	height: 100%;
`;

const VadItemTitle = styled.dt`
	font-size: ${pxToRem(12)};
	font-weight: 500;
	margin-top: ${pxToRem(8)};

	${getDesktopStyle(css`
		font-size: ${pxToRem(25)};
		margin-top: ${pxToRem(28)};
	`)}
`;

const VadItemDescription = styled.dd`
	font-size: ${pxToRem(12)};

	${getDesktopStyle(css`
		font-size: ${pxToRem(28)};
	`)}
`;

const VadItemCounting = styled(CountUp)`
	padding-right: ${pxToRem(4)};
	${UrbaneFont};
	font-style: normal;
	font-size: ${pxToRem(22)};
	font-weight: 600;

	${getDesktopStyle(css`
		font-size: ${pxToRem(45)};
	`)}
`;

const VadItemCount = styled.span`
	padding-right: ${pxToRem(4)};
	${UrbaneFont};
	font-style: normal;
	font-size: ${pxToRem(22)};
	line-height: ${pxToRem(26)};
	font-weight: 600;

	${getDesktopStyle(css`
		font-size: ${pxToRem(45)};
		line-height: ${pxToRem(54)};
	`)}
`;
