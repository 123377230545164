export const fourPawsData = [
	{
		id: 0,
		title: '노령견 케어및 \n 장례 교육',
		description: '요양시설 설립 \n YOUTUBE 영상 채널 운영 \n 및 컨텐츠 제작',
	},
	{
		id: 1,
		title: '반려동물 사망',
		description: '반려동물 등록/말소 대행 \n 반려동물 사망 시 대처 Kit',
	},
	{
		id: 2,
		title: '투명한 장례 절차',
		description: '픽업 서비스 \n 용품 및 상품 제공 \n 개별 화장 \n 참관 가능',
	},
	{
		id: 3,
		title: '펫로스 증후군 케어',
		description: '케어 프로그램 \n 심리 상담사 교육 진행 \n 정신과 전문의 제휴',
	},
];

export const fourPawsDataMobile = [
	{
		id: 0,
		title: '노령견 케어및 장례 교육',
		description: '노령견 요양시설 설립\n유튜브 채널 운영',
	},
	{
		id: 1,
		title: '반려동물 사망',
		description: '반려동물 등록/말소 대행\n반려동물 사망 시 대처 Kit',
	},
	{
		id: 2,
		title: '투명한 장례 절차',
		description: '픽업 서비스\n용품 및 상품 제공\n개별 화장\n참관 가능',
	},
	{
		id: 3,
		title: '펫로스 증후군 케어',
		description: '케어 프로그램\n심리 상담사 교육 진행\n정신과 전문의 제휴',
	},
];
