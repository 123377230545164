import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import { ScreenshotDataType } from '../../data/petdocApp';
import { pxToRem } from '../../utils/common';

interface ScreenshotItemProps {
	itemData: ScreenshotDataType;
	isSelected: boolean;
	onClick: (id: number) => void;
}

const ScreenshotItem = ({ itemData, isSelected, onClick }: ScreenshotItemProps) => {
	const { id, image, text } = itemData;
	const handleClickItem = useCallback(() => {
		onClick(id);
	}, [id, onClick]);

	return (
		<ScreenItem>
			<ScreenImgButton onClick={handleClickItem} aria-label="스크린샷 이미지 확대하기">
				<ScreenImg src={image} isSelected={isSelected} alt="" />
			</ScreenImgButton>
			<ScreenText isSelected={isSelected}>
				<ScreenTitle>{text.title}</ScreenTitle>
				<ScreenDescription>{text.description}</ScreenDescription>
			</ScreenText>
		</ScreenItem>
	);
};

export default ScreenshotItem;

const ScreenDescription = styled.p`
	font-size: ${pxToRem(12)};
`;

const ScreenTitle = styled.p`
	font-size: ${pxToRem(20)};
	margin-bottom: ${pxToRem(8)};
`;

const ScreenText = styled.div(
	({ isSelected }: { isSelected: boolean }) => css`
		visibility: ${isSelected ? 'initial' : 'hidden'};
	`,
);

const ScreenImg = styled.img(
	({ isSelected }: { isSelected: boolean }) => css`
		height: ${isSelected ? pxToRem(444) : pxToRem(368)};
		opacity: ${isSelected ? 1 : 0.5};
		transition: height 0.3s ease-in-out;
	`,
);

const ScreenImgButton = styled.button`
	margin-bottom: ${pxToRem(36)};
	border-radius: ${pxToRem(12)};
	box-shadow: 3px 4px 11px rgba(0, 0, 0, 0.2);
`;

const ScreenItem = styled.li`
	text-align: center;
`;
