import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { getDesktopStyle, showOnDesktopStyle } from '../../styles/layout';
import { pxToRem } from '../../utils/common';

export const Outlink = ({
	text,
	href,
	color,
	styles,
	isInternalLink,
}: {
	text: string;
	href: string;
	color: string;
	isInternalLink?: boolean;
	styles?: SerializedStyles;
}) => {
	return (
		<OutlinkText
			color={color}
			href={href}
			styles={styles}
			target={isInternalLink ? '_self' : '_blank'}
			rel="noopener noreferrer"
		>
			{text}
			<Icon>
				<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M5 1H14V10" stroke={color} strokeWidth="2" />
					<path d="M14 1L1 14" stroke={color} strokeWidth="2" />
				</svg>
			</Icon>
		</OutlinkText>
	);
};

const Icon = styled.span`
	margin-top: ${pxToRem(3)};
	margin-left: ${pxToRem(8)};
`;

const OutlinkText = styled.a(
	({ color, styles }: { color: string; styles?: SerializedStyles }) => css`
		${showOnDesktopStyle};
		${getDesktopStyle(css`
			display: inline-flex;
			align-items: center;
			color: ${color};
			font-size: ${pxToRem(20)};
			line-height: ${pxToRem(29)};
			cursor: pointer;
			${styles};
		`)}
	`,
);

export default Outlink;
