import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useGetSideBarState, useSetSideBarState } from './providers/SideBarProvider';
import sidebarCloseButton from '../assets/icons/icon_sidebar_close.svg';

import { ZIndex } from '../constants/zIndex';
import { pxToRem } from '../utils/common';
import SideBarNav from './sideBarNav/SideBarNav';
import { showOnMobileStyle } from '../styles/layout';

const SideBar = () => {
	const isSideBarOpen = useGetSideBarState();
	const setIsSideBarOpen = useSetSideBarState();

	const handleSideBarClose = () => {
		setIsSideBarOpen(false);
	};

	// sideBar open 여부에 따라 body scroll 제어
	useEffect(() => {
		const body = document.querySelector('body') as HTMLElement;

		body.style.overflow = isSideBarOpen ? 'hidden' : '';
		body.style.touchAction = isSideBarOpen ? 'none' : '';
	}, [isSideBarOpen]);

	return (
		<>
			<SideBarOutSideWrapper isSideBarOpen={isSideBarOpen} />
			<SideBarNavWrapper isSideBarOpen={isSideBarOpen}>
				{isSideBarOpen && (
					<SideBarCloseButton onClick={handleSideBarClose}>
						<img src={sidebarCloseButton} alt="side menu close button" />
					</SideBarCloseButton>
				)}
				<SideBarNav />
			</SideBarNavWrapper>
		</>
	);
};

export default SideBar;

const SideBarOutSideWrapper = styled.div(
	({ isSideBarOpen }: { isSideBarOpen: boolean }) => css`
		position: fixed;
		top: 0;
		left: 0;
		width: ${isSideBarOpen ? '100%' : '0%'};
		height: 100vh;
		background-color: BLACK;
		opacity: ${isSideBarOpen ? '0.5' : '0'};
		z-index: ${ZIndex.SideBar};
		transition: opacity 0.2s ease-in-out;
	`,
);

const SideBarNavWrapper = styled.div(
	({ isSideBarOpen }: { isSideBarOpen: boolean }) =>
		css`
			position: fixed;
			top: 0;
			right: 0;
			width: ${isSideBarOpen ? pxToRem(220) : '0%'};
			height: 100vh;
			background-color: WHITE;
			z-index: ${ZIndex.SideBar};
			transition: width 0.2s ease-in-out;
		`,
);

const SideBarCloseButton = styled.div`
	${showOnMobileStyle}
	position: absolute;
	top: ${pxToRem(15)};
	left: ${pxToRem(-23)};
	cursor: pointer;
`;
