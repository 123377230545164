import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ForwardedRef, forwardRef, useRef } from 'react';
import { BLACK, WHITE } from '../../constants/colors';
import { CONTACT_INNER_ELEMENT_WIDTH, WINDOW_MIN_WIDTH } from '../../constants/layout';
import { ANCHOR_IDS } from '../../constants/urls';
import { HeaderMenuType, HeaderStyleType } from '../../data/header';
import useChangeHeaderStyle from '../../hooks/useChangeHeaderStyle';
import { getDesktopStyle, showOnDesktopStyle, showOnMobileStyle } from '../../styles/layout';
import { pxToRem } from '../../utils/common';
import AnchorLink from '../AnchorLink';
import SectionTitleSet from '../common/SectionTitleSet';
import ContactListItem from '../contactSection/ContactListItem';

const ContactSection = forwardRef((props, menuActiveRef: ForwardedRef<HTMLDivElement>) => {
	const sectionRef = useRef<HTMLElement>(null);
	useChangeHeaderStyle({
		sectionRef,
		currentType: HeaderMenuType.Contact,
		currentStyleType: HeaderStyleType.Black,
		nextStyleType: HeaderStyleType.Black,
	});

	return (
		<>
			<AnchorLink id={ANCHOR_IDS.CONTACT} />
			<div ref={menuActiveRef} />
			<Wrapper ref={sectionRef}>
				<Box>
					<TitleWrapper>
						<SectionTitleSet
							title="Contact us"
							description={
								<>
									<span css={showOnDesktopStyle}>
										펫닥의 서비스 문의 및 비지니스를 함께 하고 싶다면 메일을 보내주세요.
										<br />각 서비스의 담당자들이 답변해드리겠습니다.
									</span>
									<span css={showOnMobileStyle}>
										펫닥의 서비스 문의 및
										<br />
										비지니스 문의 메일
									</span>
								</>
							}
							textAlign="CENTER"
							color="WHITE"
						/>
					</TitleWrapper>
					<ContactListItem />
				</Box>
			</Wrapper>
		</>
	);
});

export default ContactSection;

const Wrapper = styled.section`
	background: ${BLACK};
	color: ${WHITE};
	padding: ${pxToRem(80)} ${pxToRem(24)};
	padding-bottom: ${pxToRem(72)};

	${getDesktopStyle(css`
		padding: 0;
		padding-bottom: ${pxToRem(84)};
		min-width: ${pxToRem(WINDOW_MIN_WIDTH)};
	`)};
`;

const Box = styled.div`
	margin: 0 auto;

	${getDesktopStyle(css`
		width: ${pxToRem(CONTACT_INNER_ELEMENT_WIDTH)};
	`)}
`;

const TitleWrapper = styled.div`
	width: 100%;
	${getDesktopStyle(css`
		padding-top: ${pxToRem(114)};
	`)}
`;
