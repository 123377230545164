import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React, { useRef } from 'react';
import { BLACK, WHITE } from '../../constants/colors';
import { INNER_ELEMENT_WIDTH, WINDOW_MIN_WIDTH } from '../../constants/layout';
import { ANCHOR_IDS, INTERNAL_URLS } from '../../constants/urls';
import { HeaderMenuType, HeaderStyleType } from '../../data/header';
import { adData, adDataUpdateDate } from '../../data/vAd';
import useChangeHeaderStyle from '../../hooks/useChangeHeaderStyle';
import { getDesktopStyle, showOnDesktopStyle, showOnMobileStyle } from '../../styles/layout';
import { pxToRem } from '../../utils/common';
import AnchorLink from '../AnchorLink';
import MobileLineLinkButton from '../common/MobileLineLinkButton';
import SectionTitleSet from '../common/SectionTitleSet';
import VadListItem from '../vAdSection/VadListItem';

const VAdSection = () => {
	const sectionRef = useRef<HTMLElement>(null);
	useChangeHeaderStyle({
		sectionRef,
		currentType: HeaderMenuType.Businesses,
		currentStyleType: HeaderStyleType.Black,
		nextStyleType: HeaderStyleType.White,
	});

	return (
		<>
			<AnchorLink id={ANCHOR_IDS.VAD} />
			<Wrapper ref={sectionRef}>
				<Box>
					<TitleWrapper>
						<SectionTitleSet
							title="V+Ad"
							description={
								<>
									<span css={showOnDesktopStyle}>
										반려동물 관련 집중 PIN Targeting 광고가 가능하며,
										<br />
										동물병원 방문자의 80% 이상이 광고를 보고 검색을 하는 등의 관여도가 높아 광고의 효율성을 높이실 수
										있습니다.
									</span>
									<span css={showOnMobileStyle}>
										반려동물 관련 집중 PIN Targeting 광고가
										<br />
										가능하며, 동물병원 방문자의 80% 이상이
										<br />
										광고를 보고 검색을 하는 등의 관여도가 높아
										<br />
										광고의 효율성을 높이실 수 있습니다.
									</span>
								</>
							}
							textAlign="LEFT"
							color="WHITE"
						/>
					</TitleWrapper>
					<VadListLinkDesktop to={INTERNAL_URLS.CONTACT} color={WHITE}>
						광고문의 바로가기
						<Icon>
							<svg
								width="15"
								height="15"
								viewBox="0 0 15 15"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								style={{ display: 'inline-block' }}
							>
								<path d="M5 1H14V10" stroke={WHITE} strokeWidth="2" />
								<path d="M14 1L1 14" stroke={WHITE} strokeWidth="2" />
							</svg>
						</Icon>
					</VadListLinkDesktop>
					<MobileButtonWrapper>
						<MobileLineLinkButton link={INTERNAL_URLS.CONTACT} type="WHITE" isInternalLink>
							광고문의 바로가기
						</MobileLineLinkButton>
					</MobileButtonWrapper>
					<VadListWrapper>
						{adData.map((data) => (
							<VadListItem key={data.id} data={data} />
						))}
						<UpdateDateDesktop>{adDataUpdateDate}</UpdateDateDesktop>
					</VadListWrapper>
					<UpdateDateMobile>{adDataUpdateDate}</UpdateDateMobile>
				</Box>
			</Wrapper>
		</>
	);
};

export default VAdSection;

const Wrapper = styled.section`
	color: ${WHITE};
	background: ${BLACK};
	padding: ${pxToRem(80)} ${pxToRem(24)};

	${getDesktopStyle(css`
		position: relative;
		padding-top: ${pxToRem(112)};
		padding-bottom: ${pxToRem(100)};
		min-width: ${pxToRem(WINDOW_MIN_WIDTH)};
	`)}
`;

const Box = styled.div`
	margin: 0 auto;
	padding: 0 ${pxToRem(24)};

	${getDesktopStyle(css`
		padding: 0;
		width: ${pxToRem(INNER_ELEMENT_WIDTH)};
	`)}
`;

const TitleWrapper = styled.section`
	${getDesktopStyle(css`
		width: 100%;
		margin: 0 auto ${pxToRem(32)};
	`)}
`;

const UpdateDateMobile = styled.p`
	${showOnMobileStyle};
	font-weight: 500;
	font-size: 10px;
	line-height: 24px;
	color: rgba(255, 255, 255, 0.5);
	margin-top: ${pxToRem(20)};
	text-align: center;
`;

const UpdateDateDesktop = styled.p`
	${showOnDesktopStyle};
	${getDesktopStyle(css`
		position: absolute;
		bottom: ${pxToRem(-40)};
		right: ${pxToRem(-24)};
		font-weight: 500;
		font-size: ${pxToRem(14)};
		line-height: ${pxToRem(24)};
		color: rgba(255, 255, 255, 0.5);
	`)}
`;

const VadListWrapper = styled.ul`
	position: relative;
	display: grid;
	grid: repeat(2, 1fr) / 58% 42%;
	grid-row-gap: ${pxToRem(56)};
	grid-column-gap: ${pxToRem(0)};
	margin-top: ${pxToRem(63)};
	width: 100%;

	li:nth-of-type(4) {
		grid-row: 3 / 4;
	}

	li > span {
		height: ${pxToRem(46)};
	}

	li:nth-of-type(5) > span {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
	}

	li:nth-of-type(5) > span > img {
		height: ${pxToRem(30)};
	}

	${getDesktopStyle(css`
		width: 100%;
		grid-template-rows: repeat(2, 187px);
		grid-template-columns: repeat(3, 1fr);
		grid-row-gap: ${pxToRem(76)};
		margin-top: ${pxToRem(145)};

		li:nth-of-type(2) {
			grid-column: 2 / 3;
			grid-row: 1 / 2;
		}

		li:nth-of-type(2),
		li:nth-of-type(5) {
			padding-left: ${pxToRem(24)};
		}

		li:nth-of-type(4) {
			grid-row: auto;
		}

		li > span {
			height: ${pxToRem(68)};
		}

		li:nth-of-type(5) > span > img {
			height: auto;
		}
	`)}
`;

const VadListLinkDesktop = styled(Link)`
	${showOnDesktopStyle};
	${getDesktopStyle(css`
		display: inline-flex;
		align-items: center;
		font-size: ${pxToRem(20)};
		line-height: ${pxToRem(29)};
		cursor: pointer;
	`)}
`;

const Icon = styled.span`
	margin-top: ${pxToRem(3)};
	margin-left: ${pxToRem(8)};
	cursor: pointer;
`;

const MobileButtonWrapper = styled.div`
	margin-top: ${pxToRem(28)};
	text-align: center;
`;
