import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { HeaderDataType, HeaderMenuType, HeaderStyleType } from '../../data/header';
import { HeaderStyle } from '../../styles/header';
import { RobotoFont } from '../../styles/common';
import { Link } from 'gatsby';
import arrowUpIcon from '../../assets/icons/icon_sidebar_arrow_down.svg';
import { RED } from '../../constants/colors';
import { pxToRem } from '../../utils/common';
import { ZIndex } from '../../constants/zIndex';
import SideBarSubMenu from './SideBarSubMenu';
import { useSetSideBarState } from '../providers/SideBarProvider';

export type SubMenuType = Pick<HeaderDataType, 'id' | 'link' | 'text'>;

interface SideBarNavItemProps {
	data: {
		id: number;
		link: string;
		text: string;
		type?: HeaderMenuType;
		isOuter: boolean;
		hasSubMenu: boolean;
		subMenu?: SubMenuType[];
	};
	isNoticePage?: boolean;
	activeMenu: string | null;
	handleActiveMenu: (text: string | null) => void;
}

const SideBarNavItem = ({ data, activeMenu, handleActiveMenu }: SideBarNavItemProps) => {
	const headerStyle = useMemo(() => HeaderStyle[HeaderStyleType.White], []);
	const setIsSideBarOpen = useSetSideBarState();
	const { textColor } = headerStyle;

	const { id, link, text, type, isOuter, hasSubMenu, subMenu } = data;

	// SideBar Nav 제어 (선택한 Nav active 제어)
	const handleSubMenuOpen = (hasSubMenu: boolean) => () => {
		// Story || Businesses
		if (hasSubMenu) {
			if (activeMenu !== null && activeMenu === text) {
				handleActiveMenu(null);
			} else {
				handleActiveMenu(text);
			}
		} else {
			// Contact || Recruit
			if (activeMenu !== text) {
				handleActiveMenu(text);
			}
		}
	};

	// SideBar open 여부 제어
	const handleSideBarClose = () => {
		setIsSideBarOpen(false);
	};

	return (
		<Wrapper isSubMenuOpen={activeMenu === text && activeMenu !== 'Contact' && activeMenu !== 'Recruit'}>
			<SideBarNavListItem hasSubMenu={hasSubMenu} onClick={handleSubMenuOpen(hasSubMenu)}>
				{isOuter ? (
					<StyledALink
						href={link}
						target="_blank"
						rel="noopener noreferrer"
						isSubMenuOpen={activeMenu === text}
						color={textColor}
						onClick={handleSideBarClose}
					>
						{text}
					</StyledALink>
				) : (
					<LinkWrapper isSubMenuOpen={activeMenu === text} color={textColor}>
						{hasSubMenu ? (
							<HasSubMenuNav>{text}</HasSubMenuNav>
						) : (
							<LinkNav onClick={handleSideBarClose}>
								<Link to={link}>{text}</Link>
							</LinkNav>
						)}
					</LinkWrapper>
				)}
				{hasSubMenu && <SideBarArrow src={arrowUpIcon} isSubMenuOpen={activeMenu === text} />}
			</SideBarNavListItem>
			{hasSubMenu && <SideBarSubMenu isSubMenuOpen={activeMenu === text} subMenu={subMenu} />}
		</Wrapper>
	);
};

export default SideBarNavItem;

const Wrapper = styled.li(
	({ isSubMenuOpen }: { isSubMenuOpen: boolean }) => css`
		padding: 0 ${pxToRem(24)};
		border-bottom: ${isSubMenuOpen ? 0 : pxToRem(0.5)} solid rgba(78, 82, 99, 0.5);
	`,
);

const SideBarNavListItem = styled.div(
	({ hasSubMenu }: { hasSubMenu: boolean }) => css`
		display: ${hasSubMenu ? 'flex' : 'block'};
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: ${pxToRem(12)} 0;
		cursor: pointer;
	`,
);

const LinkStyle = css`
	${RobotoFont};
	width: 100%;
	display: block;
	font-size: ${pxToRem(15)};
	line-height: ${pxToRem(18)};
`;

const StyledALink = styled.a(
	({ isSubMenuOpen, color }: { isSubMenuOpen: boolean; color: string }) => css`
		${LinkStyle}
		color: ${isSubMenuOpen ? RED : color};
		font-weight: ${isSubMenuOpen ? 600 : 400};
	`,
);

const LinkWrapper = styled.p(
	({ isSubMenuOpen, color }: { isSubMenuOpen: boolean; color: string }) => css`
		& > span,
		a {
			${LinkStyle};
			color: ${isSubMenuOpen ? RED : color};
			font-weight: ${isSubMenuOpen ? 600 : 400};
		}
	`,
);

const HasSubMenuNav = styled.span`
	display: block;
`;

const LinkNav = styled.span``;

const SideBarArrow = styled.img(
	({ isSubMenuOpen }: { isSubMenuOpen: boolean }) => css`
		${isSubMenuOpen && `transform: scaleY(-1)`};
		z-index: ${ZIndex.Header};
	`,
);
