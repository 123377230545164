import React, { ReactNode } from 'react';
import GroomingICon from '../components/icon/vcare/GroomingIcon';
import HotelIcon from '../components/icon/vcare/HotelIcon';
import KinderIcon from '../components/icon/vcare/KinderIcon';
import { IMAGE_BASE_URL } from '../constants/urls';

const VCARE_IMG_01 = `${IMAGE_BASE_URL}/vcare/vcare_image_01.png`;
const VCARE_IMG_02 = `${IMAGE_BASE_URL}/vcare/vcare_image_02.png`;
const VCARE_IMG_03 = `${IMAGE_BASE_URL}/vcare/vcare_image_03.png`;
const VCARE_IMG_04 = `${IMAGE_BASE_URL}/vcare/vcare_image_04.png`;
const VCARE_IMG_05 = `${IMAGE_BASE_URL}/vcare/vcare_image_05.png`;
const VCARE_IMG_06 = `${IMAGE_BASE_URL}/vcare/vcare_image_06.png`;
const VCARE_IMG_07 = `${IMAGE_BASE_URL}/vcare/vcare_image_07.png`;
const VCARE_IMG_08 = `${IMAGE_BASE_URL}/vcare/vcare_image_08.png`;
const VCARE_IMG_09 = `${IMAGE_BASE_URL}/vcare/vcare_image_09.png`;

export enum VCareMenuType {
	Grooming = 'grooming',
	Hotel = 'hotel',
	Kinder = 'kinder',
}

export interface MenuItemType {
	id: number;
	type: VCareMenuType;
	title: string;
	activeIcon: ReactNode;
	icon: ReactNode;
}

export const VCareMenu = [
	{ id: 0, type: VCareMenuType.Kinder, title: '유치원', activeIcon: <KinderIcon isActive />, icon: <KinderIcon /> },
	{
		id: 1,
		type: VCareMenuType.Hotel,
		title: '데일리케어·호텔',
		activeIcon: <HotelIcon isActive />,
		icon: <HotelIcon />,
	},
	{
		id: 2,
		type: VCareMenuType.Grooming,
		title: '그루밍',
		activeIcon: <GroomingICon isActive />,
		icon: <GroomingICon />,
	},
];

export const vCareData = {
	[VCareMenuType.Kinder]: {
		serviceName: '유치원',
		info: ['월 - 금요일 | 오전 8시 - 오후 9시'],
		tags: ['개별산책', '개별교육', '행동교정', '독피트니스', '놀이시간', '픽업'],
		images: [VCARE_IMG_07, VCARE_IMG_08, VCARE_IMG_09],
	},
	[VCareMenuType.Hotel]: {
		serviceName: '데일리케어·호텔',
		info: ['월 - 금요일 | 오전 8시 - 오후 9시', '토 - 일요일 | 오전 10시 - 오후 7시'],
		tags: ['개별공간보유', 'CCTV', '놀이시간'],
		images: [VCARE_IMG_04, VCARE_IMG_05, VCARE_IMG_06],
	},
	[VCareMenuType.Grooming]: {
		serviceName: '그루밍',
		info: ['화 - 토요일 | 오전 10시 - 오후 7시'],
		tags: ['클리퍼', '가위컷', '기본미용', '위생미용', '목욕', '스파', '무마취미용'],
		images: [VCARE_IMG_01, VCARE_IMG_02, VCARE_IMG_03],
	},
};
