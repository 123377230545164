import styled from '@emotion/styled';
import React from 'react';
import { A11y, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { REAL_BLACK, WHITE } from '../../constants/colors';
import { fourPawsDataMobile } from '../../data/fourpaws';
import { showOnMobileStyle } from '../../styles/layout';
import { swiperPagenationStyle } from '../../styles/swiper';
import { pxToRem } from '../../utils/common';

const FourPawsListItemMobile = () => {
	return (
		<FourPawsListWrapper>
			<Swiper
				modules={[Pagination, A11y, Autoplay]}
				loop={true}
				pagination={{
					clickable: true,
				}}
				autoplay={{
					delay: 3500,
					disableOnInteraction: false,
				}}
			>
				{fourPawsDataMobile.map((data) => (
					<SwiperSlide key={data.id}>
						<FourPawsItemList key={data.id}>
							<FourPawsItemCircle>
								<FourPawsItemTitle>
									{data.title.split('\n').map((title, index) => {
										return <p key={index}>{title}</p>;
									})}
								</FourPawsItemTitle>
								<FourPawsItemDescription>
									{data.description.split('\n').map((description, index) => {
										return <p key={index}>{description}</p>;
									})}
								</FourPawsItemDescription>
							</FourPawsItemCircle>
						</FourPawsItemList>
					</SwiperSlide>
				))}
			</Swiper>
		</FourPawsListWrapper>
	);
};

export default FourPawsListItemMobile;

const FourPawsListWrapper = styled.ul`
	${showOnMobileStyle};
	margin-top: ${pxToRem(168)};

	.swiper {
		height: calc(35vw + 50px);
	}

	${swiperPagenationStyle({ bgColor: REAL_BLACK, activeColor: WHITE })};
`;

const FourPawsItemList = styled.li`
	text-align: center;
	color: ${WHITE};
`;

const FourPawsItemCircle = styled.dl`
	display: inline-block;
	line-height: normal;
	vertical-align: middle;
`;

const FourPawsItemTitle = styled.dt`
	font-weight: 700;
	font-size: ${pxToRem(16)};
	line-height: ${pxToRem(20)};
	margin-bottom: ${pxToRem(10)};

	p {
		font-weight: 700;
	}
`;

const FourPawsItemDescription = styled.dd`
	font-weight: 500;
	font-size: ${pxToRem(14)};
	line-height: ${pxToRem(22)};
`;
