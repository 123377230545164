import { css } from '@emotion/react';
import React, { ReactNode } from 'react';
import { getDesktopStyle } from '../../styles/layout';
import { pxToRem } from '../../utils/common';
import SectionDescription from './SectionDescription';
import SectionTitle from './SectionTitle';

interface SectionTitleSetParams {
	title: string | ReactNode;
	description: string | ReactNode;
	color?: 'WHITE' | 'BLACK';
	textAlign?: 'LEFT' | 'CENTER';
}

// 섹션 제목과 설명
const SectionTitleSet = ({ title, description, color, textAlign }: SectionTitleSetParams) => {
	return (
		<>
			<SectionTitle color={color} textAlign={textAlign} styles={sectionTitleStyle}>
				{title}
			</SectionTitle>
			<SectionDescription color={color} textAlign={textAlign}>
				{description}
			</SectionDescription>
		</>
	);
};

export default SectionTitleSet;

const sectionTitleStyle = css`
	margin-bottom: ${pxToRem(24)};
	${getDesktopStyle(css`
		margin-bottom: ${pxToRem(20)};
	`)}
`;
