import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { BLACK, WHITE } from '../../constants/colors';
import { getDesktopStyle } from '../../styles/layout';
import { pxToRem } from '../../utils/common';

interface DescriptionStyleParams {
	color?: 'WHITE' | 'BLACK';
	textAlign?: 'LEFT' | 'CENTER';
	styles?: SerializedStyles;
}

interface SectionDescriptionParams extends DescriptionStyleParams {
	children: ReactNode;
}

// 섹션 설명
const SectionDescription = ({ children, color, textAlign, styles }: SectionDescriptionParams) => {
	return (
		<Description color={color} textAlign={textAlign} styles={styles}>
			{children}
		</Description>
	);
};

const Description = styled.p(
	({ color, textAlign, styles }: DescriptionStyleParams) => css`
		color: ${color === 'WHITE' ? WHITE : BLACK};
		font-size: ${pxToRem(15)};
		line-height: ${pxToRem(24)};
		font-weight: 500;
		text-align: center;
		${styles}

		${getDesktopStyle(css`
			font-size: ${pxToRem(20)};
			line-height: ${pxToRem(28)};
			text-align: ${textAlign === 'CENTER' ? 'center' : 'left'};
		`)}
	`,
);

export default SectionDescription;
