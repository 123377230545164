import { IMAGE_BASE_URL } from '../constants/urls';

const PETMILY_IMG_01 = `${IMAGE_BASE_URL}/petmily/petmily_image_01.png`;
const PETMILY_IMG_02 = `${IMAGE_BASE_URL}/petmily/petmily_image_02.png`;
const PETMILY_IMG_03 = `${IMAGE_BASE_URL}/petmily/petmily_image_03.png`;
const PETMILY_IMG_04 = `${IMAGE_BASE_URL}/petmily/petmily_image_04.png`;

export const slideData = [
	{ id: 0, image: PETMILY_IMG_01, text: '데이터 기반으로\n정확한 고객 니즈를 파악하는 IT Center' },
	{ id: 1, image: PETMILY_IMG_02, text: '반려동물에 대한\n전문적인 지식을 보유한 수의사' },
	{ id: 2, image: PETMILY_IMG_03, text: '깨끗하고 믿을 수 있는 원료로 제조하여\nHACCP 인증을 받은 펫푸드 제조사' },
	{ id: 3, image: PETMILY_IMG_04, text: '실제로 사용해보고 꼼꼼히 따져보는\n펫테랑 리뷰어・에디터' },
];
