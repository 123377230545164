import React, { ReactNode } from 'react';
import { IMAGE_BASE_URL } from '../constants/urls';

const APP_IMG_01 = `${IMAGE_BASE_URL}/app/app_image_01.png`;
const APP_IMG_02 = `${IMAGE_BASE_URL}/app/app_image_02.png`;
const APP_IMG_03 = `${IMAGE_BASE_URL}/app/app_image_03.png`;
const APP_IMG_04 = `${IMAGE_BASE_URL}/app/app_image_04.png`;

export interface ScreenshotDataType {
	id: number;
	image: any;
	text: {
		title: string;
		description: ReactNode;
	};
}

export const appScreenData = [
	{
		id: 0,
		image: APP_IMG_01,
		text: {
			title: '온라인 수의사 상담',
			description: (
				<>
					반려동물에 대한 궁금한 사항을
					<br />
					펫닥의 수의사가 답변해드립니다.
				</>
			),
		},
	},
	{
		id: 1,
		image: APP_IMG_02,
		text: {
			title: '병원예약',
			description: (
				<>
					가장 가까운 동물병원에 원하시는
					<br />
					시간대에 예약 하실 수 있습니다.
				</>
			),
		},
	},
	{
		id: 2,
		image: APP_IMG_03,
		text: {
			title: '펫닥 CARE',
			description: (
				<>
					펫닥 스캐너를 통해서 집에서 <br />
					간편하게 반려동물 컨디션을 체크해보세요.
				</>
			),
		},
	},
	{
		id: 3,
		image: APP_IMG_04,
		text: {
			title: '브이랩 쇼핑',
			description: (
				<>
					펫닥의 수의사가 함께한 식품&용품을
					<br />
					간편하게 구매하실 수 있습니다.
				</>
			),
		},
	},
];
