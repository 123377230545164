import Layout from '../components/Layout';
import Main from '../components/Main';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const IndexPage = () => {
	return (
		<Layout>
			<Main />
		</Layout>
	);
};

export default IndexPage;
