import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { BLACK, WHITE } from '../../constants/colors';
import { UrbaneFont } from '../../styles/common';
import { getDesktopStyle } from '../../styles/layout';
import { pxToRem } from '../../utils/common';

interface TitleStyleParams {
	color?: 'WHITE' | 'BLACK';
	textAlign?: 'LEFT' | 'CENTER';
	styles?: SerializedStyles;
}

interface SectionTitleParams extends TitleStyleParams {
	children: ReactNode;
}

// 섹션 제목
const SectionTitle = ({ children, color, textAlign, styles }: SectionTitleParams) => {
	return (
		<Title color={color} textAlign={textAlign} styles={styles}>
			{children}
		</Title>
	);
};

const Title = styled.h1(
	({ color, textAlign, styles }: TitleStyleParams) => css`
		${UrbaneFont};
		color: ${color === 'WHITE' ? WHITE : BLACK};
		text-align: center;
		font-size: ${pxToRem(30)};
		line-height: ${pxToRem(35)};
		font-weight: 600;
		${styles}

		${getDesktopStyle(css`
			font-size: ${pxToRem(70)};
			line-height: ${pxToRem(80)};
			text-align: ${textAlign === 'CENTER' ? 'center' : 'left'};
		`)}
	`,
);

export default SectionTitle;
