import React from 'react';

const PagenationArrow = ({ color, direction }: { color: string; direction: 'LEFT' | 'RIGHT' }) => {
	return (
		<svg
			width="19"
			height="26"
			viewBox="0 0 19 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				transform: direction === 'LEFT' ? 'none' : 'scaleX(-1)',
				filter: 'drop-shadow(rgba(0, 0, 0, 0.5) 0px 4px 2px)',
			}}
		>
			<path
				opacity="0.5"
				d="M17 2L3.10697 12.1382C2.5596 12.5376 2.5596 13.3543 3.10697 13.7537L17 23.8919"
				stroke={color}
				strokeWidth="4"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default PagenationArrow;
