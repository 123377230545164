import React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { pxToRem } from '../../utils/common';
import { SubMenuType } from './SideBarNavItem';

interface SideBarSubMenuProps {
	data: SubMenuType;
	handleSideBarClose: () => void;
}

const SideBarSubMenuItem = ({ data, handleSideBarClose }: SideBarSubMenuProps) => {
	const { link, text } = data;

	return (
		<SubMenuItem onClick={handleSideBarClose}>
			<Link to={link}>{text}</Link>
		</SubMenuItem>
	);
};

export default SideBarSubMenuItem;

const SubMenuItem = styled.li`
	& > a {
		display: block;
		font-size: ${pxToRem(13)};
		line-height: ${pxToRem(32)};
		font-weight: 400;
	}
`;
