import { ResposeDataType } from '../types/common';
import { BASE_URL, HTTP } from '../utils/http';

export interface EmailRequestData {
	company: string; // 회사
	department: string; // 부서
	tel: string; // 연락처
	email: string; // 이메일
	content: string; // 내용
	manager: string; // 담당자
	receiveMailAddr: string; // 담당자 메일
}

export interface EmailResponseData {
	code: string; // 응답코드
	resultData: EmailRequestData; // 결과 데이터
	message: string; //	응답 메세지
}

const EMAIL_URL = '/api/v3/contacts';

export const EmailApi = {
	postEmail: async (params: EmailRequestData) => {
		const response = await HTTP.post<EmailRequestData, ResposeDataType<null>>(`${BASE_URL}${EMAIL_URL}`, params);
		return response.data;
	},
};
