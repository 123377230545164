import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useEffect, useRef, useState } from 'react';
import { BLACK } from '../../constants/colors';
import { WINDOW_MIN_WIDTH } from '../../constants/layout';
import { ANCHOR_IDS, IMAGE_BASE_URL } from '../../constants/urls';
import { HeaderMenuType, HeaderStyleType } from '../../data/header';
import useChangeHeaderStyle from '../../hooks/useChangeHeaderStyle';
import { getDesktopStyle } from '../../styles/layout';
import { pxToRem } from '../../utils/common';
import { isMobile } from '../../utils/userAgent';
import AppDownloadDesktop from '../heroSection/AppDownloadDesktop';
import AppDownloadMobile from '../heroSection/AppDownloadMobile';
import petdocMainImage from '../../dx_img/pc-petdoc-main-img.jpg'; // 피씨 이미지 경로 추가
import petdocMobileImage from '../../dx_img/mb-petdoc-main-img.jpg'; // 모바일 이미지 경로 추가
import petdocTextImage from '../../dx_img/main-title-text.png'; // 텍스트 이미지

const VIDEO_URL_DESKTOP = `${IMAGE_BASE_URL}/video/hero_video_desktop.mp4`;
const VIDEO_URL_MOBILE = `${IMAGE_BASE_URL}/video/hero_video_mobile.mp4`;

const HeroSection = () => {
	const [isMobile, setIsMobile] = useState(false);
	// const [videoUrl, setVideoUrl] = useState('');
	const sectionRef = useRef<HTMLElement>(null);
	useChangeHeaderStyle({
		sectionRef,
		currentType: HeaderMenuType.Story,
		currentStyleType: HeaderStyleType.Background,
		nextStyleType: HeaderStyleType.White,
	});

	useEffect(() => {
		function isMobileCheck() {
			return window.innerWidth <= 768;
		}
		function handleResize() {
			setIsMobile(isMobileCheck());
		}

		window.addEventListener('resize', handleResize);

		handleResize();
		return () => {
			window.removeEventListener('resize', handleResize);
		};
		// setVideoUrl(isMobile() ? VIDEO_URL_MOBILE : VIDEO_URL_DESKTOP);
	}, []);

console.log(isMobile)

	return (
		<Wrapper id={ANCHOR_IDS.STORY} ref={sectionRef}>
			{/*<Video loop playsInline autoPlay muted src={videoUrl} />*/}
			<MainImgBox>
				<img src={isMobile ? petdocMobileImage : petdocMainImage} alt="PetDoc Main Image" />
				<img src={petdocTextImage} alt="PetDoc Main Image" />
			</MainImgBox>
			<AppDownloadDesktop />
			<AppDownloadMobile />
		</Wrapper>
	);
};

export default HeroSection;

const MainImgBox = styled.div`
	width: 100%;
	height : 404px;
	position: relative;
	 > img:first-of-type{
		 display: block;
		 width: 100%;
		 height: 100%;
		 position: absolute;
		 left: 0;
		 top: 0;
		 object-fit: cover;
		 object-position: center center;
	 }
	> img:last-of-type{
		width: 100%;
		max-width: 260px;
		position: absolute;
		bottom: 43px;
		left: calc(50% - 120px);
		${getDesktopStyle(css`
			width: 100%;
			max-width: 887px;
			position: absolute;
			bottom: 113px;
			left: calc(50% - 443.5px);
		`)}
	}
		${getDesktopStyle(css`
			width: 100%;
			height : 900px;
			position: relative;
		`)}
`

const Video = styled.video`
	width: auto;
	height: 100%;
	object-fit: cover;

	${getDesktopStyle(css`
		width: 100%;
		height: auto;
		object-fit: contain;
	`)}
`;

const Wrapper = styled.section`
	position: relative;
	height: 404px;
	background: ${BLACK};

	${getDesktopStyle(css`
		width: 100%;
		height: auto;
		min-width: ${pxToRem(WINDOW_MIN_WIDTH)};
	`)}
`;
