import React, { useState } from 'react';
import styled from '@emotion/styled';
import AppLogoImg from '../../assets/images/logo_btn.svg';
import AppQrImg from '../../assets/images/img_download.svg';
import { ZIndex } from '../../constants/zIndex';
import { RED } from '../../constants/colors';
import { pxToRem } from '../../utils/common';
import { getDesktopStyle, showOnDesktopStyle } from '../../styles/layout';
import { css } from '@emotion/react';

const AppDownloadDesktop = () => {
	const [isHovering, setIsHovering] = useState(0);
	const onMouseOver = () => {
		setIsHovering(1);
	};
	const onMouseOut = () => {
		setIsHovering(0);
	};

	return (
		<DownloadBox onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
			<DownloadButton
				style={{
					...(isHovering
						? { marginRight: 0, borderRadius: `${pxToRem(10)} 0 0 ${pxToRem(10)}` }
						: { marginRight: pxToRem(-130) }),
				}}
			>
				{isHovering ? (
					<img src={AppQrImg} alt="PETDOC APP DOWNLOAD QR IMG" />
				) : (
					<img src={AppLogoImg} alt="PETDOC APP LOGO IMG" />
				)}
			</DownloadButton>
		</DownloadBox>
	);
};

export default AppDownloadDesktop;

const DownloadBox = styled.div`
	${showOnDesktopStyle};
	position: fixed;
	right: 0;
	top: ${pxToRem(360)};
	z-index: ${ZIndex.AppDownloadButton};
`;

const DownloadButton = styled.div`
	width: ${pxToRem(195)};
	height: ${pxToRem(220)};
	margin-right: ${pxToRem(-130)};
	background: ${RED};
	transition: margin 0.2s ease-in-out;
`;
