import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { headerData } from '../../data/header';
import SideBarNavItem from './SideBarNavItem';

const SideBarNav = () => {
	const [activeMenu, setActiveMenu] = useState(null);
	const handleActiveMenu = useCallback(
		(text) => {
			setActiveMenu(text);
		},
		[activeMenu],
	);

	return (
		<SideBarNavWrapper>
			{headerData.map((header) => {
				return (
					<SideBarNavItem key={header.id} data={header} activeMenu={activeMenu} handleActiveMenu={handleActiveMenu} />
				);
			})}
		</SideBarNavWrapper>
	);
};

export default SideBarNav;

const SideBarNavWrapper = styled.ul`
	width: 100%;
`;
