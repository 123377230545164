import { IMAGE_BASE_URL } from '../constants/urls';

const PARTNERS_IMG_01 = `${IMAGE_BASE_URL}/partners/partners_image_01.png`;
const PARTNERS_IMG_02 = `${IMAGE_BASE_URL}/partners/partners_image_02.png`;
const PARTNERS_IMG_03 = `${IMAGE_BASE_URL}/partners/partners_image_03.png`;
const PARTNERS_IMG_04 = `${IMAGE_BASE_URL}/partners/partners_image_04.png`;
const PARTNERS_IMG_05 = `${IMAGE_BASE_URL}/partners/partners_image_05.png`;
const PARTNERS_IMG_06 = `${IMAGE_BASE_URL}/partners/partners_image_06.png`;

export const partnersData = [
	{ id: 0, image: PARTNERS_IMG_01, alt: '엑스레이 사진을 보는 수의사와 개' },
	{ id: 1, image: PARTNERS_IMG_02, alt: '수술중인 수의사' },
	{ id: 2, image: PARTNERS_IMG_03, alt: '강아지용 간식' },
	{ id: 3, image: PARTNERS_IMG_04, alt: '검사를 받는 고양이' },
	{ id: 4, image: PARTNERS_IMG_05, alt: '강아지용 간식' },
	{ id: 5, image: PARTNERS_IMG_06, alt: '검사를 받는 개' },
];
