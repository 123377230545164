import { useRef } from 'react';
import AppSection from '../components/section/AppSection';
import ContactSection from '../components/section/ContactSection';
import FourPawsSection from '../components/section/FourPawsSection';
import SideBar from './SideBar';
import HeroSection from '../components/section/HeroSection';
import HistorySection from '../components/section/HistorySection';
import PetmilyLabSection from '../components/section/PetmilyLabSection';
import VAdSection from '../components/section/VAdSection';
import VCareSection from '../components/section/VCareSection';
import VetSection from '../components/section/VetSection';
import VLabSection from '../components/section/VLabSection';
import VPartnersSection from '../components/section/VPartnersSection';
import useChangeHeaderMenuMobile from '../hooks/useChangeHeaderMenuMobile';

const Main = () => {
	const storyMenuActiveRef = useRef<HTMLDivElement>(null);
	const contactMenuActiveRef = useRef<HTMLDivElement>(null);
	useChangeHeaderMenuMobile({ storyRef: storyMenuActiveRef, contactRef: contactMenuActiveRef });

	return (
		<main>
			<SideBar />
			<HeroSection />
			<HistorySection />
			<VetSection />
			<PetmilyLabSection />
			<AppSection ref={storyMenuActiveRef} />
			<VPartnersSection />
			<FourPawsSection />
			<VLabSection />
			<VAdSection />
			<VCareSection />
			<ContactSection ref={contactMenuActiveRef} />
		</main>
	);
};

export default Main;
