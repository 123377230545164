import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ChangeEvent } from 'react';
import { BLACK, GRAY_03 } from '../../constants/colors';
import { getDesktopStyle } from '../../styles/layout';
import { pxToRem } from '../../utils/common';

interface InputParams {
	label: string;
	name: string;
	placeholder?: string;
	value: string | ReadonlyArray<string> | number;
	onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

const ContactFormTextarea = ({ label, name, placeholder, value, onChange }: InputParams) => {
	return (
		<ContactFormLabel>
			<ContactFormLabelText>{label}</ContactFormLabelText>
			<Textarea name={name} value={value} placeholder={placeholder} onChange={onChange} />
		</ContactFormLabel>
	);
};

export default ContactFormTextarea;

const ContactFormLabel = styled.label`
	${getDesktopStyle(css`
		margin-top: ${pxToRem(28)};
		&:first-of-type {
			margin-top: 0;
		}
		&:last-of-type {
			margin-top: 0;
			padding-left: ${pxToRem(70)};
		}
		&:last-of-type > span {
			width: ${pxToRem(60)};
		}
	`)}
`;

const ContactFormLabelText = styled.span`
	display: inline-block;
	margin-top: ${pxToRem(12)};
	font-size: ${pxToRem(12)};
	margin-bottom: ${pxToRem(4)};

	${getDesktopStyle(css`
		margin-right: ${pxToRem(20)};
		width: ${pxToRem(45)};
		vertical-align: top;
		margin-top: 0;
		margin-bottom: 0;
	`)}
`;

const Textarea = styled.textarea`
	font-size: ${pxToRem(16)};
	width: 100%;
	height: ${pxToRem(195)};
	padding: ${pxToRem(11)} ${pxToRem(16)};
	border: 1px solid ${BLACK};
	border-radius: ${pxToRem(4)};
	resize: none;
	outline: none;

	&::placeholder {
		color: ${GRAY_03};
	}

	${getDesktopStyle(css`
		font-size: ${pxToRem(12)};
		padding: ${pxToRem(11)} ${pxToRem(30)};
		width: ${pxToRem(424)};
		height: ${pxToRem(307)};
	`)}
`;
