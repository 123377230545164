import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ChangeEvent, HTMLInputTypeAttribute } from 'react';
import { BLACK, GRAY_03 } from '../../constants/colors';
import { getDesktopStyle } from '../../styles/layout';
import { InputModeType } from '../../types/form';
import { pxToRem } from '../../utils/common';

interface InputParams {
	label: string;
	type?: HTMLInputTypeAttribute;
	name: string;
	placeholder?: string;
	value: string | ReadonlyArray<string> | number;
	inputMode?: InputModeType;
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const ContactFormInput = ({ label, type, name, placeholder, value, onChange, inputMode }: InputParams) => {
	return (
		<ContactFormLabel>
			<ContactFormLabelText>{label}</ContactFormLabelText>
			<ContactFormInputText
				type={type}
				name={name}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				inputMode={inputMode}
			/>
		</ContactFormLabel>
	);
};

export default ContactFormInput;

const ContactFormLabel = styled.label`
	margin-top: ${pxToRem(28)};

	&:first-of-type {
		margin-top: 0;
	}

	&:last-of-type {
		margin-top: 0;
		padding-left: ${pxToRem(70)};
	}

	&:last-of-type > span {
		width: ${pxToRem(60)};
	}
`;

const ContactFormLabelText = styled.span`
	display: inline-block;
	margin-top: ${pxToRem(12)};
	margin-bottom: ${pxToRem(4)};
	font-size: ${pxToRem(12)};
	font-weight: 700;

	${getDesktopStyle(css`
		margin-right: ${pxToRem(20)};
		width: ${pxToRem(33)};
		vertical-align: top;
		margin-top: 0;
		margin-bottom: 0;
	`)}
`;

const ContactFormInputText = styled.input`
	font-size: ${pxToRem(16)};
	width: 100%;
	height: ${pxToRem(39)};
	padding: ${pxToRem(11)} ${pxToRem(16)};
	border: 1px solid ${BLACK};
	border-radius: ${pxToRem(4)};
	outline: none;

	&::placeholder {
		color: ${GRAY_03};
	}

	&::-webkit-inner-spin-button {
		appearance: none;
		-moz-appearance: none;
		-webkit-appearance: none;
	}

	${getDesktopStyle(css`
		font-size: ${pxToRem(12)};
		width: ${pxToRem(332)};
	`)}
`;
