import { IMAGE_BASE_URL } from '../constants/urls';

const VLAB_IMG_01 = `${IMAGE_BASE_URL}/vlab/vlab_image_01.png`;
const VLAB_IMG_02 = `${IMAGE_BASE_URL}/vlab/vlab_image_02.png`;
const VLAB_IMG_03 = `${IMAGE_BASE_URL}/vlab/vlab_image_03.png`;
const VLAB_IMG_04 = `${IMAGE_BASE_URL}/vlab/vlab_image_04.png`;
const VLAB_THUMB_IMG_01 = `${IMAGE_BASE_URL}/vlab/vlab_image_thumb_01.png`;
const VLAB_THUMB_IMG_02 = `${IMAGE_BASE_URL}/vlab/vlab_image_thumb_02.png`;
const VLAB_THUMB_IMG_03 = `${IMAGE_BASE_URL}/vlab/vlab_image_thumb_03.png`;
const VLAB_THUMB_IMG_04 = `${IMAGE_BASE_URL}/vlab/vlab_image_thumb_04.png`;

export const vlabData = [
	{
		id: 0,
		image: VLAB_IMG_01,
		thumbImage: VLAB_THUMB_IMG_01,
		text: {
			description: '강아지 구강 구조에 맞춘 덴탈 케어 간식',
			title: '펫닥 브이랩 입맞춤 양치껌',
		},
	},
	{
		id: 1,
		image: VLAB_IMG_02,
		thumbImage: VLAB_THUMB_IMG_02,
		text: {
			description: '강아지&고양이 맞춤 멀티케어 영양간식',
			title: '펫닥 브이랩 짜먹는 영양 7일 헬스밸런스',
		},
	},
	{
		id: 2,
		image: VLAB_IMG_03,
		thumbImage: VLAB_THUMB_IMG_03,
		text: {
			description: '장 건강에 도움이 되는 4세대 유산균',
			title: '펫닥 브이랩 포스트바이오틱스',
		},
	},
	{
		id: 3,
		image: VLAB_IMG_04,
		thumbImage: VLAB_THUMB_IMG_04,
		text: {
			description: '100% 원재료 그대로 국내 생산한',
			title: '펫닥 브이랩 자연그대로 수제간식',
		},
	},
];
