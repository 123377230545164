import axios from 'axios';
import { EmailApi, EmailRequestData } from '../api/Email';

class EmailServices {
	static async processGetEmail({
		InputParams,
		successCallback,
		errorCallback,
	}: {
		InputParams: EmailRequestData;
		successCallback: () => void;
		errorCallback: (message?: string) => void;
	}) {
		try {
			const response = await EmailApi.postEmail(InputParams);
			if (response.code === 'SUCCESS') {
				successCallback();
			} else {
				errorCallback(response.messageKey);
			}
		} catch (e: any) {
			if (axios.isAxiosError(e) && e.response) {
				alert(e.response?.data?.message || '문제가 발생했습니다. 고객센터에 문의해주세요.');
			} else {
				alert('문제가 발생했습니다. 고객센터에 문의해주세요.');
			}
		}
	}
}

export default EmailServices;
