import { RefObject, useEffect, useState } from 'react';
import { useSetHeaderStyle } from '../components/providers/HeaderStyleProvider';
import { MOBILE_HEADER_HEIGHT } from '../constants/layout';
import { HeaderMenuType } from '../data/header';
import { throttle } from '../utils/common';
import { isMobile } from '../utils/userAgent';

const mobileScrollTargets = {
	story: 2289 - MOBILE_HEADER_HEIGHT,
	businesses: 6746 - MOBILE_HEADER_HEIGHT,
};

// 모바일용 Header 메뉴 변경 이벤트
const useChangeHeaderMenuMobile = ({
	storyRef,
	contactRef,
}: {
	storyRef: RefObject<HTMLElement>;
	contactRef: RefObject<HTMLElement>;
}) => {
	const setHeaderMenu = useSetHeaderStyle();
	const [scrollTargets, setScrollTargets] = useState(mobileScrollTargets);

	useEffect(() => {
		if (storyRef.current && contactRef.current) {
			setScrollTargets({
				story: storyRef.current.offsetTop - MOBILE_HEADER_HEIGHT,
				businesses: contactRef.current.offsetTop - MOBILE_HEADER_HEIGHT,
			});
		}
	}, []);

	useEffect(() => {
		const isInMobile = isMobile();

		const setActiveMenu = throttle(() => {
			if (window.scrollY <= scrollTargets.story) {
				setHeaderMenu((prev) => ({ ...prev, activeMenuType: HeaderMenuType.Story }));
			} else if (window.scrollY > scrollTargets.story + 1 && window.scrollY <= scrollTargets.businesses - 1) {
				setHeaderMenu((prev) => ({ ...prev, activeMenuType: HeaderMenuType.Businesses }));
			} else {
				setHeaderMenu((prev) => ({ ...prev, activeMenuType: HeaderMenuType.Contact }));
			}
		}, 100);

		if (isInMobile) {
			window.addEventListener('scroll', setActiveMenu);
		}

		return () => {
			if (isInMobile) {
				window.removeEventListener('scroll', setActiveMenu);
			}
		};
	}, [scrollTargets]);
};

export default useChangeHeaderMenuMobile;
