import contactIcon01 from '../assets/icons/icon_sns_instagram.svg';
import contactIcon02 from '../assets/icons/icon_sns_youtube.svg';
import { OUTER_URLS } from '../constants/urls';

export const contactData = [
	{
		id: 0,
		title: 'V+Partners',
		description: '제품 입점 ・ 소개서 받기',
		link: 'vpartners@petdoc.co.kr',
	},
	{
		id: 1,
		title: 'FOUR PAWS',
		description: '제휴 문의',
		link: 'fourpaws@petdoc.co.kr',
	},
	{ id: 2, title: 'V+Lab', description: '제품입점 제안 ・ 제안서 받기', link: 'vlab@petdoc.co.kr' },
	{ id: 3, title: 'V+Ad', description: '매체 소개서 받기 ・ 광고 문의하기', link: 'hyunistar1981@petdoc.co.kr' },
	{ id: 4, title: 'V+Care', description: '브이케어 이용 문의하기', link: 'Kakao Talk', linkId: '(ID : v_care)' },
	{ id: 5, title: '광고문의', description: '광고 및 제휴 문의', link: 'hello@petdoc.co.kr' },

	{
		id: 6,
		title: '주소',
		description: '서울특별시 서초구 강남대로79길 32',
		map: 'https://map.naver.com/v5/search/%ED%8E%AB%EB%8B%A5/place/38556155?c=14139620.1584721,4509683.9630234,15,0,0,0,dh ',
	},
	{
		id: 7,
		title: '고객센터',
		description: 'Tel. 02.417.3002 월 - 금 (10:00-18:00)',
	},
];

export const contactSnsData = [
	{ id: 0, icon: contactIcon01, link: OUTER_URLS.INSTAGRAM },
	{ id: 1, icon: contactIcon02, link: OUTER_URLS.YOUTUBE },
];
