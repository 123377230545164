import styled from '@emotion/styled';
import { RED, WHITE } from '../../constants/colors';
import { ZIndex } from '../../constants/zIndex';
import { showOnMobileStyle } from '../../styles/layout';
import { pxToRem } from '../../utils/common';

const APP_DOWNLOAD_LINK = 'https://petdoc.page.link/Zmya';

const AppDownloadMobile = () => {
	return (
		<LinkButton href={APP_DOWNLOAD_LINK}>
			<ButtonText>APP Download</ButtonText>
		</LinkButton>
	);
};

export default AppDownloadMobile;

const ButtonText = styled.span`
	writing-mode: vertical-rl;
	text-orientation: mixed;
	font-weight: 700;
	font-size: ${pxToRem(12)};
	line-height: ${pxToRem(14)};
	color: ${WHITE};
`;

const LinkButton = styled.a`
	${showOnMobileStyle};
	position: fixed;
	right: 0;
	bottom: 19.3vh;
	z-index: ${ZIndex.AppDownloadButton};
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${RED};
	width: ${pxToRem(34)};
	padding: ${pxToRem(8)} ${pxToRem(10)};
`;
