import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useEffect, useRef, useState } from 'react';
import { A11y, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BLACK, GRAY_01, WHITE } from '../../constants/colors';
import { INNER_ELEMENT_WIDTH, SECTION_HEIGHT, WINDOW_MIN_WIDTH } from '../../constants/layout';
import { ANCHOR_IDS, OUTER_URLS } from '../../constants/urls';
import { HeaderMenuType, HeaderStyleType } from '../../data/header';
import { partnersData } from '../../data/vPartners';
import useChangeHeaderStyle from '../../hooks/useChangeHeaderStyle';
import { getDesktopStyle, showOnDesktopStyle, showOnMobileStyle } from '../../styles/layout';
import { swiperPagenationStyle } from '../../styles/swiper';
import { pxToRem } from '../../utils/common';
import { isMobile } from '../../utils/userAgent';
import AnchorLink from '../AnchorLink';
import MobileLineLinkButton from '../common/MobileLineLinkButton';
import Outlink from '../common/Outlink';
import SectionTitleSet from '../common/SectionTitleSet';

const VPartnersSection = () => {
	const [titleSetColor, setTitleSetColor] = useState<'BLACK' | 'WHITE'>('WHITE');
	const sectionRef = useRef<HTMLElement>(null);
	useChangeHeaderStyle({
		sectionRef,
		currentType: HeaderMenuType.Businesses,
		currentStyleType: HeaderStyleType.Black,
		nextStyleType: HeaderStyleType.White,
	});

	useEffect(() => {
		setTitleSetColor(isMobile() ? 'BLACK' : 'WHITE');
	}, []);

	return (
		<>
			<AnchorLink id={ANCHOR_IDS.VPARTNERS} />
			<Wrapper ref={sectionRef}>
				<Box>
					<TitleWrapper>
						<SectionTitleSet
							title="V+Partners"
							description={
								<>
									<span css={showOnDesktopStyle}>
										수의사 분들의 수월한 동물병원 운영을 위해
										<br />
										간편 통합 마켓 플레이스를 제공하고 있습니다.
									</span>
									<span css={showOnMobileStyle}>
										수의사 분들의 수월한 동물병원 운영을 위해
										<br />
										간편 통합 마켓 플레이스를 제공합니다.
									</span>
								</>
							}
							textAlign="LEFT"
							color={titleSetColor}
						/>
					</TitleWrapper>
					<Outlink text="홈페이지 바로가기" href={OUTER_URLS.VPARTNER} color={WHITE} />
					<MobileButtonWrapper>
						<MobileLineLinkButton link={OUTER_URLS.VPARTNER} type="BLACK">
							홈페이지 바로가기
						</MobileLineLinkButton>
					</MobileButtonWrapper>
					<ImgWrapperDesktop>
						{partnersData.map((data) => (
							<PartnersItems key={data.id}>
								<PartnersItemsImg src={data.image} alt={data.alt} />
							</PartnersItems>
						))}
					</ImgWrapperDesktop>
					<ImgWrapperMobile>
						<Swiper
							modules={[Pagination, A11y, Autoplay]}
							loop={true}
							pagination={{
								clickable: true,
							}}
							autoplay={{
								delay: 2000,
								disableOnInteraction: false,
							}}
							spaceBetween={15}
						>
							{partnersData.map((data) => (
								<SwiperSlide key={data.id}>
									<PartnersItems>
										<PartnersItemsImg src={data.image} alt="" />
									</PartnersItems>
								</SwiperSlide>
							))}
						</Swiper>
					</ImgWrapperMobile>
				</Box>
			</Wrapper>
		</>
	);
};

export default VPartnersSection;

const Wrapper = styled.section`
	background: ${GRAY_01};
	padding: ${pxToRem(80)} ${pxToRem(24)};
	color: ${BLACK};

	${getDesktopStyle(css`
		padding: 0;
		position: relative;
		color: ${WHITE};
		background: ${BLACK};
		height: ${pxToRem(SECTION_HEIGHT)};
		min-width: ${pxToRem(WINDOW_MIN_WIDTH)};
	`)}
`;

const Box = styled.div`
	margin: 0 auto;

	${getDesktopStyle(css`
		width: ${pxToRem(INNER_ELEMENT_WIDTH)};
	`)}
`;

const TitleWrapper = styled.section`
	width: 100%;

	${getDesktopStyle(css`
		padding-top: ${pxToRem(114)};
		margin: 0 auto ${pxToRem(32)};
	`)}
`;

const ImgWrapperDesktop = styled.ul`
	${showOnDesktopStyle};
	${getDesktopStyle(css`
		width: 100%;
		display: flex;
		flex-flow: row wrap;
		margin-top: ${pxToRem(81)};
	`)}
`;

const ImgWrapperMobile = styled.div`
	${showOnMobileStyle};

	margin-top: ${pxToRem(40)};

	.swiper {
		height: calc(62.5vw + 50px);
	}

	${swiperPagenationStyle({ bgColor: WHITE, activeColor: BLACK })};
`;

const PartnersItems = styled.li`
	${getDesktopStyle(css`
		width: ${pxToRem(336)};
		margin-left: ${pxToRem(24)};
		margin-bottom: ${pxToRem(24)};

		&:nth-of-type(3n + 1) {
			margin-left: 0;
		}
	`)}
`;

const PartnersItemsImg = styled.img`
	width: 100%;
`;

const MobileButtonWrapper = styled.div`
	margin-top: ${pxToRem(28)};
	text-align: center;
`;
