import React from 'react';
import { GRAY_03, WHITE } from '../../../constants/colors';

const HotelIcon = ({ isActive = false }: { isActive?: boolean }) => {
	return (
		<svg width="52" height="47" viewBox="0 0 52 47" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask id="path-1-inside-1_226_8084" fill="white">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M39.7959 10.8052H12.2041V17.0903H0V46.8049H52V17.0903H39.7959V10.8052Z"
				/>
			</mask>
			<path
				d="M12.2041 10.8052V8.80518H10.2041V10.8052H12.2041ZM39.7959 10.8052H41.7959V8.80518H39.7959V10.8052ZM12.2041 17.0903V19.0903H14.2041V17.0903H12.2041ZM0 17.0903V15.0903H-2V17.0903H0ZM0 46.8049H-2V48.8049H0V46.8049ZM52 46.8049V48.8049H54V46.8049H52ZM52 17.0903H54V15.0903H52V17.0903ZM39.7959 17.0903H37.7959V19.0903H39.7959V17.0903ZM12.2041 12.8052H39.7959V8.80518H12.2041V12.8052ZM14.2041 17.0903V10.8052H10.2041V17.0903H14.2041ZM0 19.0903H12.2041V15.0903H0V19.0903ZM2 46.8049V17.0903H-2V46.8049H2ZM52 44.8049H0V48.8049H52V44.8049ZM50 17.0903V46.8049H54V17.0903H50ZM39.7959 19.0903H52V15.0903H39.7959V19.0903ZM37.7959 10.8052V17.0903H41.7959V10.8052H37.7959Z"
				fill={isActive ? WHITE : GRAY_03}
				mask="url(#path-1-inside-1_226_8084)"
			/>
			<rect x="44" y="22" width="3" height="3.91993" fill={isActive ? WHITE : GRAY_03} />
			<rect x="44" y="27.04" width="3" height="3.91993" fill={isActive ? WHITE : GRAY_03} />
			<rect x="44" y="32.0801" width="3" height="3.91993" fill={isActive ? WHITE : GRAY_03} />
			<rect x="5" y="22" width="3" height="3.91993" fill={isActive ? WHITE : GRAY_03} />
			<rect x="5" y="27.04" width="3" height="3.91993" fill={isActive ? WHITE : GRAY_03} />
			<rect x="5" y="32.0801" width="3" height="3.91993" fill={isActive ? WHITE : GRAY_03} />
			<rect x="17" y="16" width="4" height="6" fill={isActive ? WHITE : GRAY_03} />
			<rect x="17" y="24" width="4" height="6" fill={isActive ? WHITE : GRAY_03} />
			<rect x="24" y="16" width="4" height="6" fill={isActive ? WHITE : GRAY_03} />
			<rect x="24" y="24" width="4" height="6" fill={isActive ? WHITE : GRAY_03} />
			<rect x="31" y="16" width="4" height="6" fill={isActive ? WHITE : GRAY_03} />
			<rect x="31" y="24" width="4" height="6" fill={isActive ? WHITE : GRAY_03} />
			<path
				d="M16.5 3L17.0613 4.72746H18.8776L17.4082 5.79508L17.9695 7.52254L16.5 6.45492L15.0305 7.52254L15.5918 5.79508L14.1224 4.72746H15.9387L16.5 3Z"
				fill={isActive ? WHITE : GRAY_03}
			/>
			<path
				d="M26 0L26.8981 2.76393H29.8042L27.4531 4.47214L28.3511 7.23607L26 5.52786L23.6489 7.23607L24.5469 4.47214L22.1958 2.76393H25.1019L26 0Z"
				fill={isActive ? WHITE : GRAY_03}
			/>
			<path
				d="M35.5 3L36.0613 4.72746H37.8776L36.4082 5.79508L36.9695 7.52254L35.5 6.45492L34.0305 7.52254L34.5918 5.79508L33.1224 4.72746H34.9387L35.5 3Z"
				fill={isActive ? WHITE : GRAY_03}
			/>
		</svg>
	);
};

export default HotelIcon;
