import { RefObject, useCallback, useEffect } from 'react';
import { useSetHeaderStyle } from '../components/providers/HeaderStyleProvider';
import { HEADER_HEIGHT } from '../constants/layout';
import { HeaderMenuType, HeaderStyleType } from '../data/header';
import { isMobile } from '../utils/userAgent';

const MENU_THRESHOLD = [0.1, 1];
const STYLE_THRESHOLD = [0.1, 1];

const useChangeHeaderStyle = ({
	sectionRef,
	currentType,
	nextType,
	currentStyleType,
	nextStyleType,
	styleThreshold,
}: {
	sectionRef: RefObject<HTMLElement>;
	currentType: HeaderMenuType;
	nextType?: HeaderMenuType;
	currentStyleType: HeaderStyleType;
	nextStyleType: HeaderStyleType;
	styleThreshold?: number[];
}) => {
	const setHeaderStyle = useSetHeaderStyle();

	const handleSetHeaderMenu = useCallback(
		(menuType: HeaderMenuType) => setHeaderStyle((prev) => ({ ...prev, activeMenuType: menuType })),
		[setHeaderStyle],
	);

	const handleSetHeaderStyle = useCallback(
		(styleType: HeaderStyleType) => setHeaderStyle((prev) => ({ ...prev, headerStyleType: styleType })),
		[setHeaderStyle],
	);

	const onMenuObserve = useCallback(
		(callback: (menuType: HeaderMenuType) => void) => (entries: IntersectionObserverEntry[]) => {
			entries.forEach((entry) => {
				if (nextType && entry.boundingClientRect.bottom <= HEADER_HEIGHT && entry.boundingClientRect.bottom >= 0) {
					callback(nextType);
				}

				if (entry.isIntersecting && entry.boundingClientRect.top <= HEADER_HEIGHT) {
					callback(currentType);
				}
			});
		},
		[nextType, currentType],
	);

	const onStyleObserve = useCallback(
		(callback: (styleType: HeaderStyleType) => void) => (entries: IntersectionObserverEntry[]) => {
			entries.forEach((entry) => {
				if (entry.boundingClientRect.bottom <= HEADER_HEIGHT && entry.boundingClientRect.bottom >= 0) {
					callback(nextStyleType);
				}

				if (entry.isIntersecting && entry.boundingClientRect.top <= HEADER_HEIGHT) {
					callback(currentStyleType);
				}
			});
		},
		[currentStyleType, nextStyleType],
	);

	useEffect(() => {
		const isInMobile = isMobile();

		let menuObserver: IntersectionObserver;
		let styleObserver: IntersectionObserver;

		try {
			if (sectionRef?.current) {
				styleObserver = new IntersectionObserver(onStyleObserve(handleSetHeaderStyle), {
					threshold: styleThreshold || STYLE_THRESHOLD,
				});
				styleObserver.observe(sectionRef.current);
				if (!isInMobile) {
					menuObserver = new IntersectionObserver(onMenuObserve(handleSetHeaderMenu), {
						threshold: MENU_THRESHOLD,
					});
					menuObserver.observe(sectionRef.current);
				}
			}
		} catch (error) {
			return undefined;
		}

		return () => {
			menuObserver?.disconnect();
			styleObserver?.disconnect();
		};
	}, [handleSetHeaderStyle, onStyleObserve, sectionRef]);
};

export default useChangeHeaderStyle;
