import { Agent, AgentRegexPattern } from '../constants/platform';

export const getPlatform = (userAgent: string = navigator?.userAgent) => {
	if (AgentRegexPattern.AndroidWeb.test(userAgent)) {
		return Agent.ANDROID_WEB;
	}
	if (AgentRegexPattern.iOSWeb.test(userAgent)) {
		return Agent.IOS_WEB;
	}
	if (!AgentRegexPattern.MobileWeb.test(userAgent)) {
		return Agent.PC_WEB;
	}
	return Agent.ETC;
};

// 클라이언트단 접근인지 인식
export const isClient = () => typeof window !== 'undefined';

// 모바일 브라우저에서 접속했는지 여부 판단
export const isMobile = () => isClient() && [Agent.ANDROID_WEB, Agent.IOS_WEB].includes(getPlatform());
