import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import { DEEPGREEN, GRAY_03 } from '../../constants/colors';
import { MenuItemType, VCareMenuType } from '../../data/vCare';
import { getDesktopStyle } from '../../styles/layout';
import { pxToRem } from '../../utils/common';

interface MenuIconItemProps {
	itemData: MenuItemType;
	isSelected: boolean;
	onClick: (type: VCareMenuType) => void;
}

const MenuIconItem = ({ itemData, isSelected, onClick }: MenuIconItemProps) => {
	const { id, type, activeIcon, icon, title } = itemData;
	const handleClickItem = useCallback(() => {
		onClick(type);
	}, [id, onClick]);

	return (
		<Icon key={id} isSelected={isSelected}>
			<IconButton
				type="button"
				onClick={handleClickItem}
				isSelected={isSelected}
				aria-label={`${title} 내용 자세히 보기`}
			>
				<IconWrapper>{isSelected ? activeIcon : icon}</IconWrapper>
			</IconButton>
			<p>{title}</p>
		</Icon>
	);
};

export default MenuIconItem;

const IconWrapper = styled.div`
	transform: scale(0.9);

	${getDesktopStyle(css`
		transform: scale(1);
	`)}
`;

const IconButton = styled.button(
	({ isSelected }: { isSelected: boolean }) => css`
		display: flex;
		justify-content: center;
		align-items: center;
		border: ${isSelected ? `none` : `2px solid ${GRAY_03}`};
		background-color: ${isSelected ? DEEPGREEN : 'none'};
		border-radius: ${pxToRem(5)};
		width: ${pxToRem(72)};
		height: ${pxToRem(72)};
		margin-bottom: ${pxToRem(8)};

		${getDesktopStyle(css`
			width: ${pxToRem(87)};
			height: ${pxToRem(87)};
		`)}
	`,
);

const Icon = styled.li(
	({ isSelected }: { isSelected: boolean }) => css`
		color: ${isSelected ? DEEPGREEN : GRAY_03};
		font-size: ${pxToRem(16)};
		display: flex;
		flex-direction: column;
		align-items: center;

		${getDesktopStyle(css`
			font-size: ${pxToRem(15)};
		`)}
	`,
);
