import { SerializedStyles } from '@emotion/react';
import { ButtonColorType, MobileLineButtonStyle } from '../../styles/button';

const MobileLineButton = ({
	type = 'WHITE',
	text,
	onClick,
	style,
}: {
	type?: ButtonColorType;
	text: string;
	onClick: () => void;
	style?: SerializedStyles;
}) => {
	return (
		<button css={[MobileLineButtonStyle({ type }), style]} onClick={onClick}>
			{text}
		</button>
	);
};

export default MobileLineButton;
